<template>
  <div class="nav" :style="{background: background}">
    <div class="nav-inner">
      <a href="/" class="logo">
        <img :src="logo" alt="logo"/>
      </a>
      <ul class="navigation">
        <li v-for="page in pages" :key="page.name" :class="currentPage == page.name ? 'selected':''">
          <a :href="page.url" :style="{color: textColor}">{{page.title}}</a>
        </li>
      </ul>
      <a href="#" class="btn-nav-panel-open" @click="panelOpen = true">
        <img :src="navIcon" alt="">
      </a>
    </div>
    <div class="nav-panel animate__animated animate__fadeInRight" v-if="panelOpen">
      <div>
        <a href="#" class="btn-nav-panel-close" @click="panelOpen = false">
          <img src="/assets/icons/close-bg-blue.png" alt="">
        </a>
      </div>
      <div class="nav-panel-bg-top animate__animated animate__fadeInLeft"></div>
      <div class="nav-panel-bg-bottom"></div>
      <ul>
        <li class="animate__animated animate__fadeInUp" v-for="page in pages" :key="page.name" :class="currentPage == page.name ? 'selected':''">
          <a :href="page.url">{{page.title}}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import store from '@/store'

export default {
  data: () => ({
    pages: [
      {
        name: 'home',
        title: 'Home',
        url: '/'
      },
      {
        name: 'documentation',
        title: 'Documentation',
        url: '/documentation'
      },
      {
        name: 'solution',
        title: 'Solution',
        url: '/solution'
      },
      {
        name: 'localization',
        title: 'Localization',
        url: '/localization'
      },
      {
        name: 'portfolio',
        title: 'Portfolio',
        url: '/portfolio'
      },
      {
        name: 'about',
        title: 'About',
        url: '/about'
      },
      {
        name: 'blog',
        title: 'Blog',
        url: '/blog'
      },
      {
        name: 'contact',
        title: 'Contact',
        url: '/contact'
      }
    ],
    panelOpen: false,
    light: {
      color: '#FFFFFF',
      logo: '/assets/images/textree_logo_white.png',
      navIcon: '/assets/icons/nav-icon-gray.png'
    },
    dark: {
      color: '#959595',
      logo: '/assets/images/textree_logo_blue.png',
      navIcon: '/assets/icons/nav-icon-white.png'
    }
  }),
  computed: {
    logo: function () {
      switch (store.state.nav.theme) {
        case 'lightLogoDark':
          return this.light.logo
        case 'darkLogoLight':
          return this.dark.logo
        case 'dark':
          return this.dark.logo
        case 'light':
        default:
          return this.light.logo
      }
    },
    navIcon: function () {
      switch (store.state.nav.theme) {
        case 'lightLogoDark':
          return this.dark.navIcon
        case 'darkLogoLight':
          return this.light.navIcon
        case 'dark':
          return this.light.navIcon
        case 'light':
        default:
          return this.dark.navIcon
      }
    },
    textColor: function () {
      switch (store.state.nav.theme) {
        case 'lightLogoDark':
          return this.dark.color
        case 'darkLogoLight':
          return this.light.color
        case 'dark':
          return this.dark.color
        case 'light':
        default:
          return this.light.color
      }
    },
    background: function () {
      switch (store.state.nav.theme) {
        case 'lightLogoDark':
          return this.dark.background
        case 'darkLogoLight':
          return this.light.background
        case 'dark':
          return this.dark.background
        case 'light':
        default:
          return this.light.background
      }
    },
    currentPage: () => store.state.nav.currentPage
  }
}
</script>

<style scoped>
.nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 66px;
  font-family: 'Roboto';
  display: flex;
  align-items: center;
  z-index: 1024;
}
.nav-inner {
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
  height: calc(100% - 24px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.logo {
  height: 100%;
  padding: 0 24px;
}
.logo img {
  height: 100%;
}
.navigation {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: calc(100% - 37.5%);
  padding-left: 0;
}
.navigation li {
  padding: 0 24px;
}
.navigation li a {
  text-transform: uppercase;
  text-decoration: none;
}
.navigation li.selected a {
  border-bottom: 5px solid;
  padding-bottom: 5px;
}

div.nav-panel,
a.btn-nav-panel-open {
  display: none;
  animation: fadeInRight;
  animation-duration: .50s;
}

@media(max-width: 1366px) {
  .navigation li:last-child {
    margin-left: 0;
  }
  .navigation li {
    padding: 0 12px;
  }
  .navigation {
    justify-content: flex-end;
  }
}

@media(max-width: 1024px) {
  ul.navigation {
    display: none;
  }

  a.btn-nav-panel-open {
    display: block;
    margin: 0 32px;
  }
  a.btn-nav-panel-open img {
    width: 24px;
    height: 16px;
  }

  div.nav-panel {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: white;
  }

  div.nav-panel-bg-top {
    position: absolute;
    top: 25%;
    left: -70%;
    right: 70%;
    height: 30%;
    background-image: url('/assets/images/TEXTree-logo-icon.png');
    background-repeat: no-repeat;
    background-size: contain;
    transform: rotate(135deg);
    z-index: 1;
  }
  div.nav-panel-bg-bottom {
    position: absolute;
    bottom: 0;
    right: -70%;
    left: 70%;
    height: 30%;
    background-image: url('/assets/images/TEXTree-logo-icon.png');
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1;
  }

  div.nav-panel a.btn-nav-panel-close {
    position: absolute;
    top: 24px;
    right: 24px;
  }
  div.nav-panel a.btn-nav-panel-close img {
    width: 32px;
  }

  div.nav-panel ul {
    list-style-type: none;
    padding-left: 0;
    text-align: center;
    z-index: 1024;
  }
  div.nav-panel ul li a {
    font-family: "Nanum Gothic";
    font-size: 32px;
    font-weight: bolder;
    text-decoration: none;
    color: #0D0D0D;
    line-height: 64px;
  }
  div.nav-panel ul li.selected a {
    color: #008ED6;
  }

  div.nav-panel ul li:last-child {
    margin-top: 48px;
  }
  div.nav-panel ul li:last-child a {
    font-size: 20px;
    font-weight: normal;
  }
}

@media(max-width: 526px) {
  div.nav-panel-bg-top {
    top: 0;
    left: -75%;
    right: 75%;
    height: 35%;
  }
  div.nav-panel-bg-bottom {
    bottom: -10%;
    right: -65%;
    left: 65%;
    height: 35%;
  }
}
</style>
